import * as React from "react"
import { graphql, navigate } from "gatsby"
import { useEffect } from 'react';
import Layout from "../components/layout"
import Seo from "../components/seo"
import FieldDisplay from "../components/field-display"
import queryString from "query-string"

const BlogPostTemplate = ({ data, location, pageContext}) => {

  useEffect(() => {

      const qp = queryString.parse(location.search);
      if(qp && qp.recordId){
      console.log("Redirecting...");
      navigate("/social/" + qp.recordId);
      }
    }, [location.search]);

  if(!data || (data && !data.airtable)){

    const qp = queryString.parse(location.search);

    return (<Layout location={location} title="Social Dance Today" backTo="Socials In Region">
      <Seo
        title="Social Dance Event Information"
        description="Tap for details & directions to this social dance event."
        image="https://dl.airtable.com/.attachments/317d552293769d007838f38ceaeeb527/41251728/SocialDance.jpg"
      />
      <header>
      <p>{(qp && qp.recordId && "Loading information...")|| ""}</p>
      </header>
      </Layout>);
  }else{
    const social = data && data.airtable && data.airtable.data;
    const siteTitle = data.site.siteMetadata?.title || `Title`
    let imageSource = social.ImageURL || (social.Image && social.Image[0].url) || "";
    let name = social.Name;
    let description = social.Description;

    if(pageContext.data.imageOverride){
      imageSource = pageContext.data.imageOverride[0].url;
    }
    if(pageContext.data.nameOverride){
      name = pageContext.data.nameOverride;
    }
    if(pageContext.data.descriptionOverride){
      description = pageContext.data.descriptionOverride;
    }


    return (
      <Layout location={location} title={siteTitle} backTo={"See All Socials In " + social && social.Linked_Region_Name && social.Linked_Region_Name[0]} backToLink={"/socials/"+(social && social.Linked_region_slug && social.Linked_region_slug[0])}>
        <Seo
          title={name}
          description={description}
          image={imageSource}
        />
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h1 itemProp="headline">{name}</h1>
          </header>

         {social.Confirmed &&
            <div style={{paddingBottom: 10}}>
              <span class="event-confirmed">✅ It's on! This event is confirmed by the host.</span>
            </div>
          }

          <img
            alt="social.Name"
            src={imageSource}
            style={{maxWidth: "100%"}}
          />

          <section
            itemProp="articleBody">
            <p>{description}</p>

            <div>
            {social.Dance_Styles.map(danceStyle => {
              return <span key={danceStyle} className="dance-style">{danceStyle}</span>
            })}
            </div>

            <br/>

            <table className="details-table">
              <tbody>
                <FieldDisplay label="When" data={social.Day}/>
                <FieldDisplay label="Start" data={social.Start_Time}/>
                <FieldDisplay label="End" data={social.End_Time}/>
                <FieldDisplay label="Cost" data={social.Cost}/>
                <FieldDisplay label="Parking" data={social.Parking__from_Venue_}/>
                <FieldDisplay label="Parking Cost " data={social.Parking_Cost__from_Venue_}/>
                <FieldDisplay label="Lesson" data={social.Lesson}/>
                <FieldDisplay label="Lesson Start" data={social.Lesson_Start_Time}/>
                <FieldDisplay label="Lesson End" data={social.Lesson_End_Time}/>

                <FieldDisplay label="A/C" data={social.A_C__from_Venue_}/>
                <FieldDisplay label="Dress Code" data={social.Dress_Code}/>
                <FieldDisplay label="Mirrors" data={social.Mirrors__from_Venue_}/>
                <FieldDisplay label="Venue" data={social.Type__from_Venue_}/>
                <FieldDisplay label="Floor" data={social.Floor_Type__from_Venue_} format="list"/>
                <FieldDisplay label="Avg. Turnout" data={social.Average_Number_of_People}/>
                <FieldDisplay label="Other Notes" data={social.Notes}/>
                <FieldDisplay label="Contact" data={social.Contact}/>
                <FieldDisplay label="Contact" data={social.Contact_Email}/>
                <FieldDisplay label="Contact" data={social.Contact_URL} format="link"/>
                <FieldDisplay label="Contact" data={social.Contact_Phone} format="phone"/>
              </tbody>
            </table>

            <h5>Location</h5>
            <div>{social.Name__from_Venue_}</div>
            <div>{social.Address__from_Venue_}</div>

            <p>{social.Location}</p>
            <div className="map-location"
              dangerouslySetInnerHTML={{
                __html: social.Map_Pin__from_Venue_,
              }}
              itemProp="location"
            />


          </section>
          <hr />
        </article>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    airtable(data: {RecordID: {eq: $id}}) {
       id
       data {
        RecordID
        Name
        Description
        Dance_Styles
        Day
        Start_Time
        Image {
          url
        }
        ImageURL
        End_Time
        Confirmed
        Cost
        Lesson
        Lesson_End_Time
        Lesson_Start_Time
        Venue
        Name__from_Venue_
        Address__from_Venue_
        A_C__from_Venue_
        Parking__from_Venue_
        Parking_Cost__from_Venue_
        Mirrors__from_Venue_
        Type__from_Venue_
        Floor_Type__from_Venue_
        Map_Pin__from_Venue_
        Dress_Code
        Average_Number_of_People
        Notes
        Contact
        Contact_Email
        Contact_URL
        Contact_Phone
        Linked_region_slug
        Linked_Region_Name
       }

    }

  }
`
