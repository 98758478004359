import React from "react"
import { Link } from "gatsby"

// import NumberFormat from 'react-number-format';

const FieldDisplay = ({ label, data, format }) => {

  if(data === undefined ||
      data === null ||
      data === [null] ||
      (data.length === 1 && data[0] === null) ||
      (data.length === 1 && data[0] === undefined)
      ){
    // Don't display rows for empty data
    return null;
  }

  let formattedData = data;

  if(format === "list"){
    formattedData = data.join(", ");
  }

  if(format === "link"){
    return(
      <tr>
        <td>{label}</td>
        <td className="details-col">
          <Link to={data} target="_blank">{formattedData}</Link>
        </td>
      </tr>
    )
  }else if(format === "phone"){
    return(
      <tr>
        <td>{label}</td>
        <td className="details-col">
          <a href={"tel:"+data} target="_blank">{formattedData}</a>
        </td>
      </tr>
    )
  }else{
    return(
      <tr>
        <td>{label}</td>
        <td className="details-col">{formattedData}</td>
      </tr>
    )
  }

}

export default FieldDisplay
